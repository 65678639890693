<template>
  <div class="my-courses">
    <div class="container">
      <section class="my-courses__inner">
        <base-heading bold="bold" class="dashboard-heading">
          {{ $t("myCourses.user.heading") }}
        </base-heading>

        <ul class="my-courses__list" v-if="hasCourses">
          <course-tile
            v-for="course in ownedCourses"
            :key="course.id"
            mode="dashboard"
            :course="course.course"
            :likes="course.likes"
            :show-promo="false"
            :progress="getCourseProgress(course)"
          />
          <router-link
            :to="'/courses'"
            :aria-label="$t('myCourses.user.buy')"
            class="my-courses__buy"
          >
            <strong>+</strong>
            {{ $t("myCourses.user.buy") }}
          </router-link>
        </ul>

        <div v-else class="my-courses__no-courses">
          <p class="my-courses__no-courses-text">
            {{ $t("myCourses.user.noCourses") }}
          </p>
          <base-button
            type="contrasting"
            class="my-courses__no-courses-button"
            to="/courses"
          >
            {{ $t("myCourses.user.discover") }}
          </base-button>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import course from '@/api/services/course';

export default {
  name: 'MyCourses',
  data: () => ({
    courses: [],
  }),
  computed: {
    hasCourses() {
      return this.courses.length > 0;
    },
    ownedCourses() {
      return this.courses.filter((item) => item.paymentStatus === 'paid');
    },
  },
  methods: {
    getCourseProgress(courseObject) {
      const completedLessonsLength = courseObject.completedLessons !== null
        ? courseObject.completedLessons.length
        : 0;
      const lessonsLength = courseObject.course.lessonsCount;

      // return 0;
      return Math.round((completedLessonsLength / lessonsLength) * 100);
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const response = await course.getOwnedCourses();
      this.courses = response.data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" scoped src="./MyCourses.scss" />
